<template>
    <div ref="container" class="container">
        <img alt="banner" class="cover" src="../assets/banner.png">
        <div class="banner-title">
            <div class="main-title">技能筑基&nbsp;&nbsp;&nbsp;&nbsp;未来可期</div>
            <div class="second-title">实战与理论并重，幸云IT教育打造全能型人才</div>
            <div>
                <img class="welcome" src="../assets/welcome.png" alt="welcome">
            </div>
        </div>
    </div>
</template>

<style scoped>
.container {
    position: relative;
    height: 40rem;
}

.cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.banner-title {
    font-weight: bold;
    position: absolute;
    text-align: center;
    top: 0;
    height: 40rem;
    left: 0;
    right: 0;
    color: white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-title {
    font-size: 3.2rem;
}

.second-title {
    font-size: 1.2rem;
}

.welcome {
    width: 15rem;
    margin-top: 4rem;
}

@media screen and (width < 40rem) {
    .main-title {
        font-size: 2.2rem;
    }

    .second-title {
        font-size: 0.9rem;
    }
}
</style>